import React from 'react';
import '../App.css';
import kakaotalk from '../assets/img/question_small_yellow_pc_2X.png';


const FloatingButton = (props) => {

	function handleClick(e) {
		window.location.href = 'http://pf.kakao.com/_zkExej/chat'
	}

	return (
			<div>

				<button onClick = {handleClick} title="Contact Sale" class="fixed z-20 bottom-2 right-4	">
					<img src={kakaotalk} id='floating_kakao' class="w-32 h-18 lg:w-full lg:h-full" alt="test" /> 
				 </button>
 
			</div>

	);
};

export default FloatingButton;