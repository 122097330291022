import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4"; // GA 세팅
import TagManager from 'react-gtm-module'; // GTM 세팅
import { HelmetProvider } from 'react-helmet-async';



// GA ID
const ga4Args = 'G-XXTCW2HSW9';


// GTM ID
const tagManagerArgs = {
  gtmId: 'GTM-PDHP88KK'
}


// GA 적용
ReactGA.initialize(ga4Args);

// GTM 적용
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
            <App />
        </HelmetProvider> 
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
