import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useLocation } from 'react-router-dom';

import logoImg from '../assets/img/SQ.png';





export default function Header() {

    const location = useLocation(); // url path 불러오기

    const [navigation, setNavigation] = useState([
        { name: '홈', gaName: 'gnb_home', path: '/', current: location.pathname === '/'},
        { name: '접속 이벤트', gaName: 'gnb_event', path: '/event', current: location.pathname === '/event'},
        { name: '큐브 확정 등업', gaName: 'gnb_cube', path: '/cube', current: location.pathname === '/maplepoint'},
        { name: 'MVP', gaName: 'gnb_mvp', path: '/mvp', current: location.pathname === '/mvp'},
        { name: '캐시 충전', gaName: 'gnb_cash', path: '/cash', current: location.pathname === '/recharge'},
        { name: '메이플 포인트', gaName: 'gnb_maplepoint', path: '/maplepoint', current: location.pathname === '/maplepoint'},
        { name: '후기', gaName: 'gnb_review', path: '/review', current: location.pathname === '/review'},
        { name: '공지사항', gaName: 'gnb_notice', path: '/notice', current: location.pathname === '/notice'},
        { name: '디스코드 가기', gaName: 'gnb_discord', path: 'https://discord.com/invite/7wcrj349vZ', current: location.pathname === 'https://discord.com/invite/7wcrj349vZ'},
    ]);

    function handleNavigationClick(index) {
        const updatedNavigation = navigation.map((item, i) => ({...item, current: i === index, }));
        
        setNavigation(updatedNavigation);
    }

    function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }

    return (

        <Disclosure as="nav" className="bg-white border-b-2 shadow fixed w-full z-10 font-maple">
            {({ open }) => (
            <>
                <div>
                    <div className="relative flex h-12 items-center justify-between">
                        <div className="absolute inset-y-0 left-0 flex items-center md:hidden"> </div>
                        <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                            <div className="flex flex-shrink-0 items-center">
                                <Link to='/'>
                                    <img className="h-8 w-auto" src={logoImg} alt="squad" id='gnb_logo' />
                                </Link>
                            </div>

                            {/* 데스크탑 네비게이션 바 */}
                            <div className="hidden lg:ml-4 xl:ml-14 md:block">
                                <div className="flex lg:space-x-2 xl:space-x-12 pt-1 ">
                                    {navigation.map((item,index) => (
                                        <Link to={item.path}>
                                            <a
                                                onClick={() => handleNavigationClick(index)}
                                                key={item.name}
                                                id={item.gaName}
                                                className={classNames(item.current ? 'bg-amber-400 text-white' : 'text-black hover:bg-indigo-100 hover:text-white','rounded-md px-3 py-1 md:text-xs lg:text-base font-medium')}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        </Link>
                                    ))}
                                    {/* <a className='rounded-md px-3 py-2 text-sm font-medium'> 테스트</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-4 md:hidden md:static md:inset-auto md:ml-6 md:pr-0">
                            {/* 모바일 네비게이션 바 */}
                            <Menu as="div" className="relative ml-3">
                                <div>
                                <Menu.Button className="relative flex text-sm focus:outline-none">
                                    <span class="sr-only">Open main menu</span>
                                    <svg class="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </Menu.Button>
                                </div>
                                <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {navigation.map((item,index) => (
                                        <Link to={item.path}>
                                            <Menu.Item>
                                                <a
                                                onClick={() => handleNavigationClick(index)}
                                                key={item.name}
                                                className={classNames(item.current ? 'bg-amber-400 text-white' : 'text-black hover:bg-indigo-100 hover:text-white', 'block px-4 py-2 text-sm text-gray-700')}
                                                aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </a>
                                            </Menu.Item>
                                        </Link>
                                    ))}
                                
                                </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>


            </>
            )}
        </Disclosure>
    )
}