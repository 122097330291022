// SEOMetaTag.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOMetaTag = props => {
  // props로 content 내용을 불러올 예정임
    return (
      <Helmet>
        <title> {props.title}  </title>
        <meta property="og:title" content={props.title} />
        <meta name="twitter:title" content={props.title} />
        <meta name="description" content={props.description} />
        <meta property="og:description" content={props.description} />
        <meta name="twitter:description" content={props.description} />
        <meta name="url" property="og:url" content={props.url} />
        <meta property="og:url" content={props.url} />
        <meta name="twitter:url" content={props.url}></meta>
        <link rel="canonical" href={props.url} />
      </Helmet>
    );
};



export default SEOMetaTag;
