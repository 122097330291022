import React, { Component } from "react";
import '../App.css';
import Slider from "react-slick";
import { useNavigate, Link } from 'react-router-dom';
import SEOMetaTag from '../SEOMetaTag';


import bannerEvent from '../assets/img/bannerEvent.png';
import bannerCash from '../assets/img/bannerCash.png';
import bannerMvp from '../assets/img/bannerMvp.png';
import bannerMaplepoint from '../assets/img/bannerMaplepoint.png';
import bannerCube from '../assets/img/bannerCube.png';

import gridCash from '../assets/img/gridCash.jpg';
import gridEvent from '../assets/img/gridEvent.jpg';
import gridPoint from '../assets/img/gridPoint.jpg';
import gridCube from '../assets/img/gridCube.jpg';
import gridMvp from '../assets/img/gridMvp.jpg';

import imageRedRibbon from '../assets/img/imageRedRibbon.png';
import imageBlueSnail from '../assets/img/imageBlueSnail.png';
import imageSlime from '../assets/img/imageSlime.png';
import imageToyHorse from '../assets/img/imageToyHorse.png';
import imageYeti from '../assets/img/imageYeti.png';
import imageZombie from '../assets/img/imageZombie.png';


export default function Main() {

	const navigate = useNavigate();
  
	function handleButtonClick(path) {
		navigate(path);
	}
  
	const settings = {
		dots: true,
		infinite: true,
		autoplaySpeed:4000,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		arrows: true,
		fade: true,
		appendDots: dots => (
		  <div
			style={{
			  padding: "0.6rem"
			}}
		  >
			<ul style={{ margin: "0px" }}> {dots} </ul>
		  </div>
		),


	  };
	  
	  
	  return (
		<>
			<SEOMetaTag url="http://squadgame.kr" title="스쿼드 - 메이플 PC방대리, 접속보상, 큐브 등업, MVP, 메이플포인트, 캐시" description="스쿼드는 메이플 PC방대리, 메이플 접속보상, 큐브, MVP, 캐시충전, 메이플포인트 등 다앙한 서비스를 제공합니다."/>
			<div>
				<div className = "pt-12"> 
					{/* { 배너 슬라이드 } */}
					<Slider {...settings} className = "">
						<Link to='/event'>
							<a href="" class="flex"> <img src={bannerEvent} class="w-full h-32 object-cover sm:h-48 md:h-80" alt="banner" /> </a>
						</Link>
						<Link to='/mvp'>
							<a href="" class="flex"> <img src={bannerMvp} class="w-full h-32 object-cover sm:h-48 md:h-80" alt="banner" /> </a>
						</Link>
						<Link to='/cash'>
							<a href="" class="flex"> <img src={bannerCash} class="w-full h-32 object-cover sm:h-48 md:h-80" alt="banner" /> </a>
						</Link>
						<Link to='/maplepoint'>
							<a href="" class="flex"> <img src={bannerMaplepoint} class="w-full h-32 object-cover sm:h-48 md:h-80" alt="banner" /> </a>
						</Link>
						<Link to='/cube'>
							<a href="" class="flex"> <img src={bannerCube} class="w-full h-32 object-cover sm:h-48 md:h-80" alt="banner" /> </a>
						</Link>


					</Slider>
				</div>

				

				
				{/* {그리드 배너} */}
				<div className="pt-8 font-maple">
					<div class="md:flex lg:items-center lg:justify-center lg:mx-40">
						<div class="md:w-1/2 md:h-72 lg:h-auto flex justify-end">
							<a class="place-items-center">
								<img src={gridEvent} class="md:mx-auto md:h-full" alt="test" />
							</a>
						</div>
						<div class="md:flex md:h-72 md:w-1/2 md:flex-col md:items-center md:justify-center lg:h-96 md:px-6">
							<img src={imageSlime} class="hidden md:w-40 lg:w-56 md:pb-5 md:block" alt="test" />
							<p class="text-center text-lg lg:text-4xl font-bold">PC방 접속보상 이벤트</p>
							<p class='md:pt-4'>
								<button onClick={() => handleButtonClick('/event')} id='home_event_button'
									class="bg-indigo-500 rounded-full text-white font-medium text-center w-40 h-10 px-8 py-2"> 바로가기 </button> 
							</p>
						</div>

					</div>
				</div>

				<div className="pt-8 font-maple">
					<div class="md:flex lg:items-center lg:justify-center lg:mx-40">
						<div class="md:order-2 md:w-1/2 md:h-72 lg:h-auto flex justify-end">
							<a class="place-items-center">
								<img src={gridMvp} class="md:mx-auto md:h-full" alt="test" />
							</a>
						</div>
						<div class="md:flex md:h-72 md:w-1/2 md:flex-col md:items-center md:justify-center lg:h-96 md:px-6">
							<img src={imageZombie} class="hidden md:w-28 lg:w-40 md:pt-6 md:pb-3 md:block" alt="test" />
							<p class="text-center text-lg lg:text-4xl font-bold">MVP</p>
							<p class='md:pt-4'>
								<button onClick={() => handleButtonClick('/mvp')} id='home_mvp_button' 
									class="bg-yellow-300 rounded-full text-white font-medium text-center w-40 h-10 px-8 py-2"> 바로가기 </button> 
							</p>
						</div>

					</div>
				</div>

				<div className="pt-8 font-maple">
					<div class="md:flex lg:items-center lg:justify-center lg:mx-40">
						<div class="md:w-1/2 md:h-72 lg:h-auto flex justify-end">
							<a class="place-items-center">
								<img src={gridCash} class="md:mx-auto md:h-full" alt="test" />
							</a>
						</div>
						<div class="md:flex md:h-72 md:w-1/2 md:flex-col md:items-center md:justify-center lg:h-96 md:px-6">
							<img src={imageRedRibbon} class="hidden md:w-28 lg:w-40 md:pb-5 md:block" alt="test" />
							<p class="text-center text-lg lg:text-4xl font-bold">캐시 충전</p>
							<p class='md:pt-4'>
								<button onClick={() => handleButtonClick('/cash')} id='home_cash_button'
									class="bg-indigo-500 rounded-full text-white font-medium text-center w-40 h-10 px-8 py-2"> 바로가기 </button> 
							</p>
						</div>

					</div>
				</div>

				<div className="pt-8 font-maple">
					<div class="md:flex lg:items-center lg:justify-center lg:mx-40">
						<div class="md:order-2 md:w-1/2 md:h-72 lg:h-auto flex justify-end">
							<a class="place-items-center">
								<img src={gridPoint} class="md:mx-auto md:h-full" alt="test" />
							</a>
						</div>
						<div class="md:flex md:h-72 md:w-1/2 md:flex-col md:items-center md:justify-center lg:h-96 md:px-6">
							<img src={imageBlueSnail} class="hidden md:w-28 lg:w-32 md:pt-5 md:pb-5 md:block" alt="test" />
							<p class="text-center text-lg lg:text-4xl font-bold">메이플 포인트</p>
							<p class='md:pt-4'>
								<button onClick={() => handleButtonClick('/maplepoint')} id='home_maplepoint_button'
									class="bg-yellow-300 rounded-full text-white font-medium text-center w-40 h-10 px-8 py-2"> 바로가기 </button> 
							</p>
						</div>

					</div>
				</div>


				<div className="pt-8 font-maple">
					<div class="md:flex lg:items-center lg:justify-center lg:mx-40">
						<div class="md:w-1/2 md:h-72 lg:h-auto flex justify-end">
							<a class="place-items-center">
								<img src={gridCube} class="md:mx-auto md:h-full" alt="test" />
							</a>
						</div>
						<div class="md:flex md:h-72 md:w-1/2 md:flex-col md:items-center md:justify-center lg:h-96 md:px-6">
							<img src={imageYeti} class="hidden md:w-28 lg:w-40 md:pb-5 md:block" alt="test" />
							<p class="text-center text-lg lg:text-4xl font-bold">큐브 확정 등업</p>
							<p class='md:pt-4'>
								<button onClick={() => handleButtonClick('/cube')} id='home_cube_button'
									class="bg-indigo-500 rounded-full text-white font-medium text-center w-40 h-10 px-8 py-2"> 바로 가기 </button> 
							</p>
						</div>

					</div>
				</div>


			
			</div>
  
	    </>
  
	  );
	}
