import React from 'react';
import '../App.css';

const Footer = (props) => {
	return (
		<footer class="bg-white rounded-lg  mt-12">
			<div class="w-full max-w-screen-xl mx-auto p-4 md:py-2">
				<hr class="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-2" />
				<span class="block text-xs text-gray-500 sm:text-center dark:text-gray-400 my-4">© 2023 Squad. All Rights Reserved.</span>
			</div>
		</footer>

	);
};

export default Footer;