import React from 'react';
import '../App.css';
import SEOMetaTag from '../SEOMetaTag';


export default function Notice() {
        return (
            <>
                <SEOMetaTag url="http://squadgame.kr/notice" title="스쿼드 - 메이플 공지사항" description="스쿼드는 메이플 PC방대리, 메이플 접속보상, 큐브, MVP, 캐시충전, 메이플포인트 등 다앙한 서비스를 제공합니다."/>
                <div className="font-maple relative isolate overflow-hidden bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black lg:text-2xl text-left"> 공지 사항 </p>
                            <ol className="mt-2 lg:mt-5 text-xs lg:text-base leading-8 text-black text-left list-decimal list-inside"> 
                                <li class='lg:mt-2'> <span class='text-red-600 font-semibold'> OTP 해제 필수 </span> </li>
                                <li class='lg:mt-2'> 2차 비밀번호 변경 : <span class='font-bold'>별도 공지</span> </li>
                                <li class='lg:mt-2'> 키 셋팅 안전 모드 단축키 <span class='font-bold'>F9</span>로 설정 (미 설정 시 안전 모드로 진행하지 않음.) </li>
                                <li class='lg:mt-2'> 게임 종료 전에 접속 원하는 서버로 접속 후 종료. </li>
                                <li class='lg:mt-2'> 일일 보상 수령 X, 누적 보상은 마지막 날 오전에 일괄 수령 </li>
                                <li class='lg:mt-2'> 접속 후 맵 이동 X, 꼭 안전 모드 가능한 장소에서 종료 </li>
                                <li class='lg:mt-2'> <span class='font-bold'> 개인 사정</span>으로 인해 진행하지 않거나 <span class='font-bold'>접속 시도 시 접속 중, 비활성화 설정</span> 등의 사유로 진행 불가 할 경우, <span class='font-bold underline text-red-600'>진행한 것으로 간주하며 남은 시간에서 차감. </span></li>
                            </ol>
                            <p class='mt-5 text-xs md:text-sm leading-8 text-black text-left font-bold'> ※ 공지사항을 따르지 않아 발생되는 불이익은 책임지지 않음 </p>
                        </div>

                        <div class = 'my-8'> </div>
                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 2차 비밀번호 변경 방법 </p>
                            <div class="mt-2 lg:py-8 aspect-w-16 aspect-h-9">
                                <iframe class="embed-responsive-item h-72 w-full lg:h-96 lg:w-2/3" 
                                        src="https://www.youtube.com/embed/l0MP25yHTJ4" 
                                        frameborder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                        </div>

                        <div class = 'my-8'> </div>
                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 할인 안내 </p>
                            <ol className="mt-2 lg:mt-5 text-xs lg:text-base leading-8 text-black text-left list-decimal list-inside"> 
                                <li class='lg:mt-2'> 친구와 함께 이용하시면 <span class='text-red-600 font-bold'> 1천원 할인! </span> </li>
                                <li class='lg:mt-2'> 두 계정 이상 함께 이용하셔도 <span class='text-red-600 font-bold'> 1천원 할인! </span> </li>
                                <li class='lg:mt-2'> 이전에 이벤트 이용하신 분도 <span class='text-red-600 font-bold'> 1천원 할인! </span> </li>
                            </ol>
                            <p class='mt-5 text-xs md:text-sm leading-8 text-black text-left font-bold'> ※ 할인 中 1개만 적용, 90시간 미만은 할인 적용되지 않음  </p>
                        </div>

                        <div class = 'my-8'> </div>
                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 신청 및 기타 문의 </p>
                            <div className="mt-5 flex items-center justify-left gap-x-6">
                                <a href="http://pf.kakao.com/_zkExej/chat" className="rounded-md bg-amber-500 px-3.5 py-2.5
                                    text-sm font-semibold text-white shadow-sm hover:bg-amber-400 
                                    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" id='notice_kakao_apply_button'>
                                    카카오톡 신청 문의
                                </a>
                                <a href="https://discord.com/invite/7wcrj349vZ" className="rounded-md bg-indigo-600 px-3.5 py-2.5
                                    text-sm font-semibold text-white shadow-sm hover:bg-amber-400 
                                    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" id='notice_kakao_apply_button'>
                                    디스코드 문의 하기 
                                </a>
                                <a href="https://open.kakao.com/o/goUJo2yf" className="text-sm font-semibold text-gray-900" id='notice_kakao_event_button'> 카카오톡 이벤트 공지방 <span aria-hidden="true">&rarr;</span> </a>

                            </div>
                        </div>


                    </div>
                </div>
            </>
        )
}