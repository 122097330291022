import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import toyHorseImg from '../assets/img/imageToyHorse.png';
import SEOMetaTag from '../SEOMetaTag';


export default function Cash() {
	return (
		<>
		<SEOMetaTag url="http://squadgame.kr/cash" title="스쿼드 - 메이플 캐시 충전" description="스쿼드는 메이플 PC방대리, 메이플 접속보상, 큐브, MVP, 캐시충전, 메이플포인트 등 다앙한 서비스를 제공합니다."/>
		<div className="font-maple grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
			<div className="text-center">
				<p className="text-base font-semibold text-amber-400 flex items-center justify-center pb-5">
					<img className="w-auto" src={toyHorseImg} alt="toyHorse-cash" />
				</p>
				<h1 className="text-amber-400 mt-4 text-3xl font-bold tracking-tight sm:text-5xl">캐시 충전 페이지 </h1>
				<h2 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">🛠️ 현재 오픈 준비중입니다.</h2>
				<p className="mt-6 text-base leading-7 text-gray-600">COMMING SOON</p>
				<div className="mt-10 flex items-center justify-center gap-x-6">
					<a href="/" className="rounded-md bg-amber-400 px-3.5 py-2.5 
						text-sm font-semibold text-white shadow-sm hover:bg-amber-400
						focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
						홈으로 가기
					</a>
					<a href="https://open.kakao.com/o/goUJo2yf" className="text-sm font-semibold text-gray-900"> 카카오톡 문의 <span aria-hidden="true">&rarr;</span> </a>
				</div>
			</div>
		</div>
		</>
	)
}