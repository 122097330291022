import React from 'react';
import '../App.css';
import BannerNautilus from '../assets/img/bannerNautilus.png';
import SEOMetaTag from '../SEOMetaTag';


export default function Event() {
        return (
            <>
                <SEOMetaTag url="http://squadgame.kr/cube" title="스쿼드 - 메이플 큐브 확정 등업" description="스쿼드는 메이플 PC방대리, 메이플 접속보상, 큐브, MVP, 캐시충전, 메이플포인트 등 다앙한 서비스를 제공합니다."/>
                <div className="font-maple relative isolate overflow-hidden bg-white py-16 sm:py-18">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="pb-8 w-full lg:mx-0 px-3 lg:px-12">
                            <img src={BannerNautilus} class="w-full h-32 object-cover sm:h-48 md:h-60" alt="banner-cube" />
                        </div>
                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 윗잠 </p>
                            <p className="mt-2 text-sm md:text-lg text-left"> 유니크 → 레전더리 <span class='text-red-600 font-medium px-2'>10만원</span> </p>
                        </div>

                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 에디셔녈 </p>
                            <p className="mt-2 text-sm md:text-lg text-left"> 에픽 → 유니크 <span class='text-red-600 font-medium px-2'>8만원</span> </p>
                            <p className="mt-2 text-sm md:text-lg text-left"> 유니크 → 레전드리 <span class='text-red-600 font-medium px-2'>22만원</span> </p>
                        </div>


                    </div>
                </div>
            </>
        )
}