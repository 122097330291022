import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RouteChangeTracker from "./RouteChangeTracker"; // GA 트래킹
import SEOMetaTag from './SEOMetaTag'; // SEO태그


import Header from './pages/header';
import Main from './pages/main';
import Event from './pages/event';
import Cube from './pages/cube';
import Cash from './pages/cash';
import Mvp from './pages/mvp';
import MaplePoint from './pages/maplePoint';
import Review from './pages/review';
import Notice from './pages/notice';
import Footer from './pages/footer';
import FloatingContact from './pages/floatingContact';
import WorkInProgress from './pages/workInProgress';
import NotFound from './pages/notFound';


export default function App() {
  RouteChangeTracker(); // GA 트래킹

  return (
		<div className='App'>
          <Header /> 
          <Routes>
              <Route path="/" element={<Main/>}></Route>
              <Route path="/event" element={<Event />}></Route>
              <Route path="/mvp" element={<Mvp />}></Route>
              <Route path="/cube" element={<Cube />}></Route>
              <Route path="/mvp" element={<Notice />}></Route>
              <Route path="/cash" element={<Cash />}></Route>
              <Route path="/maplepoint" element={<MaplePoint />}></Route>
              <Route path="/review" element={<Review />}></Route>
              <Route path="/notice" element={<Notice />}></Route>
              {/* <Route path="/maplePoint" element={<MaplePoint />}></Route> */}
              <Route path="*" element={<NotFound />}></Route>
          </Routes>
          <Footer />
          <FloatingContact />

      
		</div>
  )
}