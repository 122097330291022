import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import BannerErev from '../assets/img/bannerErev.png';
import SEOMetaTag from '../SEOMetaTag';


export default function Mvp() {
	return (
		<>
			<SEOMetaTag url="http://squadgame.kr/mvp" title="스쿼드 - 메이플 MVP작" description="스쿼드는 메이플 PC방대리, 메이플 접속보상, 큐브, MVP, 캐시충전, 메이플포인트 등 다앙한 서비스를 제공합니다."/>
			<div className="font-maple relative isolate overflow-hidden bg-white py-16 sm:py-18">
				<div className="mx-auto max-w-7xl lg:px-8">
					<div className="pb-8 w-full lg:mx-0 px-3 lg:px-12">
						<img src={BannerErev} class="w-full h-32 object-cover sm:h-48 md:h-60" alt="banner" />
					</div>
					<div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
						<p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 작업 가격과 시간 </p>
						<ul class='mx-1 mt-2 lg:mt-5 text-sm md:text-lg leading-8 text-black text-left list-disc list-inside'> 
							<li class='lg:mt-3'> 10,000원 당 1,500원 수수료/상품권 한도 </li>
							<li class='lg:mt-3'> 작업시간은 조율해서 진행하며 10만원당 1시간 소요됩니다. </li>

						</ul>
					</div>

					{/* 보상 목록 */}
					<div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
						<table class="mt-2 lg:mt-5 min-w-full text-center text-xs md:text-sm">
							<thead class="border-b font-medium dark:border-neutral-500  text-white text-xs md:text-base">
								<tr>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 text-black">보상 목록</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-gray-300">🔘 실버</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-amber-400">📀 골드</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-blue-300">💎 다이아</th>
									<th scope="col" class="border-b-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-red-500">🛑 	레드</th>
								</tr>
							</thead>
							<tbody>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">회복물약</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 150 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 200 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 무한대 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 무한대 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">슈퍼파워 버프</td>
									<td rowSpan={4} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3 </td>
									<td rowSpan={4} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 5 </td>
									<td rowSpan={4} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 10 </td>
									<td rowSpan={4} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 15 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black bg-gray-200 whitespace-nowrap px-2 md:px-6 py-2 md:py-4">확성기</td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">몬파 이용권</td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black bg-gray-200 whitespace-nowrap px-2 md:px-6 py-2 md:py-4">2배쿠폰(30분)</td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">경뿌50%</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 5 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 10 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">경쿠50%(교환가능)</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 10 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 20 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 30 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-r-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">심신수련관(3시간)</td>
									<td colSpan={4} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 2 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">심신수련관(12시간)</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 1 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 2 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-100 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td colSpan={5} class="whitespace-nowrap py-4 text-center">* 1일~10일/ 11일~20일/21일~말일 까지 매월 3회 획득 가능</td>
								</tr>
							</tbody>
						</table>
					</div>

					{/* 달성보상 */}
					<div className="py-12 w-full lg:mx-0 px-3 lg:px-12">
						<table class="mt-2 lg:mt-5 min-w-full text-center text-xs lg:text-sm">
							<thead class="border-b font-medium dark:border-neutral-500 text-white text-xs md:text-base">
								<tr>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 text-black">달성 보상</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-gray-300">🔘 실버</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-amber-400">📀 골드</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-blue-300">💎 다이아</th>
									<th scope="col" class="border-b-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-red-500">🛑 레드</th>
								</tr>
							</thead>
							<tbody>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">메포</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3,000 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 6,000 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 9,000 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 15,000 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">유지보너스 메포</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 500 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 4,000 </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 9,000 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 20,000 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">명찰반지</td>
									<td rowSpan={4} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> O </td>
									<td rowSpan={6} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> O </td>
									<td rowSpan={8} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> O </td>
									<td rowSpan={8} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> O </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">말풍선반지</td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">데미지스킨</td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">칭호</td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">문장</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">망토</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">무제한 회복물약</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">의자</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">텔레포트</td>
									<td colSpan={2} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 쿨 15분 </td>
									<td colSpan={2} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 쿨 5분 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">스타포스 할인율</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3% </td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 5% </td>
									<td colSpan={2} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 10% </td>
								</tr>
							</tbody>
						</table>
					</div>

					{/* 데일리기프트 추가 보상 */}
					<div className="py-12 w-full lg:mx-0 px-3 lg:px-12">
						<table class="mt-2 lg:mt-5 min-w-full text-center text-xs lg:text-sm">
							<thead class="border-b font-medium dark:border-neutral-500 text-white text-xs md:text-base">
								<tr>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 text-black">데일리기프트 추가보상</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-gray-300">🔘 실버</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-amber-400">📀 골드</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-blue-300">💎 다이아</th>
									<th scope="col" class="border-b-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-red-500">🛑 레드</th>
								</tr>
							</thead>
							<tbody>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-r-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">마일리지</td>
									<td colSpan={4} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 1,000 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">검환불</td>
									<td class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> X </td>
									<td colSpan={3} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 1 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black bg-gray-200 whitespace-nowrap px-2 md:px-6 py-2 md:py-4">블큐</td>
									<td rowSpan={2} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 3 </td>
									<td rowSpan={2} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 5 </td>
									<td rowSpan={2} class="border border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 7 </td>
									<td rowSpan={2} class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 9 </td>
								</tr>
								<tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">화에큐</td>
								</tr>
							</tbody>
						</table>
					</div>

					{/* 달성 조건 */}
					<div className="py-12 w-full lg:mx-0 px-3 lg:px-12">
						<table class="mt-2 lg:mt-5 min-w-full text-center text-xs lg:text-sm">
							<thead class="border-b font-medium dark:border-neutral-500 text-white text-xs md:text-base">
								<tr>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 text-black">달성 조건</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-gray-300">🔘 실버</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-amber-400">📀 골드</th>
									<th scope="col" class="border-b-[1px] border-r-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-blue-300">💎 다이아</th>
									<th scope="col" class="border-b-[1px] border-black px-2 md:px-6 py-2 md:py-4 bg-red-500">🛑 레드</th>
								</tr>
							</thead>
							<tbody>
								<tr class="border-b transition duration-300 ease-in-out bg-gray-200 hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
									<td class="border-b-[1px] border-r-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4">최근 3개월 이용금액</td>
									<td class="border-b-[1px] border-r-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 30만 </td>
									<td class="border-b-[1px] border-r-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 60만 </td>
									<td class="border-b-[1px] border-r-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 90만 </td>
									<td class="border-b-[1px] border-black whitespace-nowrap px-2 md:px-6 py-2 md:py-4 font-medium"> 150만 </td>
								</tr>
							</tbody>
						</table>
					</div>


				</div>
			</div>
		</>
	)
}