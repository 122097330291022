import React from 'react';
import '../App.css';
import BannerElinia from '../assets/img/bannerElinia.png';
import SEOMetaTag from '../SEOMetaTag';

export default function Event() {
        return (
            <>
                <SEOMetaTag url="http://squadgame.kr/event" title="스쿼드 - 메이플 PC방 접속 이벤트" description="스쿼드는 메이플 PC방대리, 메이플 접속보상, 큐브, MVP, 캐시충전, 메이플포인트 등 다앙한 서비스를 제공합니다."/>
                <div className="font-maple relative isolate overflow-hidden bg-white py-16 sm:py-18">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="pb-8 w-full lg:mx-0 px-3 lg:px-12">
                            <img src={BannerElinia} class="w-full h-32 object-cover sm:h-48 md:h-60" alt="banner-event" />
                        </div>
                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 비용 </p>
                            <table class="mt-2 lg:mt-5 min-w-full text-left text-xs lg:text-sm border">
                                <thead class="border-b font-medium dark:border-neutral-500 bg-amber-400 text-white text-xs md:text-base">
                                    <tr>
                                        <th scope="col" class="border-r-[1px] px-2 lg:px-6 py-4 ">진행 시간</th>
                                        <th scope="col" class="px-2 lg:px-6 py-4">금액</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td class="border-r-[1px] whitespace-nowrap px-2 lg:px-6 py-4">1시간 (10시간 단위로 가능)</td>
                                        <td class="whitespace-nowrap px-2 lg:px-6 py-4 font-medium">￦ 1,000원 </td>
                                    </tr>
                                    <tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td class="border-r-[1px] whitespace-nowrap px-2 lg:px-6 py-4">60시간</td>
                                        <td class="whitespace-nowrap px-2 lg:px-6 py-4"> 
                                            <span class='line-through text-gray-300'> <i> ￦ 60,000원 </i>  </span>
                                            <span> </span>
                                            <span class='text-red-600 font-medium px-3'> ￦ 54,000원 </span>
                                        </td>
                                    </tr>
                                    <tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td class="border-r-[1px] whitespace-nowrap px-2 lg:px-6 py-4">90시간</td>
                                        <td class="whitespace-nowrap px-2 lg:px-6 py-4"> 
                                            <span class='line-through text-gray-300'> <i> ￦ 90,000원 </i>  </span>
                                            <span> </span>
                                            <span class='text-red-600 font-medium px-3'> ￦ 76,000원 </span>
                                        </td>
                                    </tr>
                                    <tr class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td class="border-r-[1px] whitespace-nowrap px-2 lg:px-6 py-4">120시간</td>
                                        <td class="whitespace-nowrap px-2 lg:px-6 py-4"> 
                                            <span class='line-through text-gray-300'> <i> ￦ 120,000원 </i>  </span>
                                            <span> </span>
                                            <span class='text-red-600 font-medium px-3'> ￦ 100,000원 </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="py-8 w-full lg:mx-0 px-3 lg:px-12">
                            <p className="text-lg font-bold tracking-tight text-black md:text-2xl text-left"> 진행시간 </p>
                            <ul class='mx-1 mt-2 lg:mt-5 text-sm md:text-lg leading-8 text-black text-left list-disc list-inside'> 
                                <li class='lg:mt-3'> 
                                    매일 10시간씩 진행. 
                                    <ul class='lg:mt-3 pl-7 list-disc list-inside'>
                                        <li> 00:00~12:00 中 10시간 진행. </li> 
                                    </ul>
                                </li>
                                <li class='lg:mt-3'> 신청일 다음날부터 시작하고 신청 시간이 완료되는 시점에서 종료됨. </li>
                                <li class='lg:mt-3'> 진행 시간에 영향이 가는 패치 날에는 진행하지 않음. </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
}